<template>
    <div>
        <el-row>
            <el-col>
                <el-form :inline='true'>
                    <el-form-item label='关键字:'>
                        <el-input v-model='filters.name' placeholder="输入姓名/手机号/身份证号" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="岗位名称:">
                        <el-select v-model="filters.postName" placeholder="请选择岗位名称" clearable>
                            <el-option
                                v-for="item in postNameArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行业信息名称:">
                        <el-select v-model="filters.industryName" placeholder="请选择行业信息名称" clearable>
                            <el-option
                                v-for="item in industryNameArr"
                                :key="item.Id"
                                :label="item.Name"
                                :value="item.Id"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="系统强制交卷:">
                        <el-select v-model="filters.systemExaminationPaper" placeholder="请选择是否系统强制交卷" clearable>
                            <el-option
                                v-for="item in systemExaminationPaperArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="考试行为异常:">
                        <el-select v-model="filters.examAbnormal" placeholder="请选择是否考试行为异常" clearable>
                            <el-option
                                v-for="item in systemExaminationPaperArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="审核状态:">
                        <el-select v-model="filters.checkStatus" placeholder="请选择审核状态" clearable>
                            <el-option
                                v-for="item in checkStatusArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value"
                            >
                            </el-option>
                        </el-select>
                    </el-form-item>
	                <el-form-item label="起始分数:">
		                <el-input v-model="filters.startMark" placeholder="输入分数" clearable></el-input>
	                </el-form-item>
	                <el-form-item label="最高分数:">
		                <el-input v-model="filters.endMark" placeholder="输入分数" clearable></el-input>
	                </el-form-item>
                    <el-form-item>
                        <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
                    </el-form-item>
                </el-form>             
            </el-col>
        </el-row>
        <el-table
            :data="tableData"
            border
            v-loading="tableLoading"
            @current-change='currentChange'
            :highlight-current-row='true'
            ref="multipleTable"
            @selection-change="handleSelectChange"
        >
            <!-- <el-table-column type='index' label='序号' align="center" width='60'></el-table-column> -->
            <el-table-column type="selection" width="50" align="center"></el-table-column>
            <el-table-column prop="Name" label="姓名" width="110" align="center"></el-table-column>
            <el-table-column prop="PhoneNumber" label="手机号" width="130" align="center"></el-table-column>
            <el-table-column prop="CardNo" label="身份证号" width="180" align="center" min-width="100"></el-table-column>
            <!-- <el-table-column prop="CertificateDesign" label="证书名称" show-overflow-tooltip align="center"></el-table-column> -->
            <el-table-column prop="ExamBeginTime" label="考试时间" show-overflow-tooltip align="center" min-width="100"></el-table-column>
            <el-table-column prop="TotalScore" label="成绩" show-overflow-tooltip align="center"></el-table-column>
            <el-table-column prop="ForceCommit" label="系统强制交卷" show-overflow-tooltip align="center" min-width="120">
                <template scope="scope">
                    <el-tag v-if="scope.row.ForceCommit == 0" type="success">否</el-tag>
                    <el-tag v-if="scope.row.ForceCommit == 1" type="danger">是</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="TakePhotos" label="抓拍照片" show-overflow-tooltip align="center" min-width="100">
                <template scope="scope">
                    <el-button type="text" @click="viewBatPicture(scope.row)">{{scope.row.TakePhotos}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="FailedSoundRecording" label="人声监测" show-overflow-tooltip align="center" min-width="100">
                <template scope="scope">
                    <el-button type="text" @click="viewBatSound(scope.row)">{{scope.row.FailedSoundRecording}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="FailedTakePhotos" label="人脸对比失败照片" show-overflow-tooltip align="center" min-width="140">
                <template scope="scope">
                    <el-button type="text" @click="viewabnormalPicture(scope.row)">{{scope.row.FailedTakePhotos}}</el-button>
                </template>
            </el-table-column>
            <el-table-column prop="IllegalCheck" label="考试行为异常" show-overflow-tooltip align="center" min-width="120">
                <template scope="scope">
                    <!-- <el-tag v-if="scope.row.IllegalCheck == 0" type="success">否</el-tag>
                    <el-tag v-if="scope.row.IllegalCheck == 1" type="danger">是</el-tag> -->
                    <el-button v-if="scope.row.IllegalCheck == 0" plain size="mini" type="success" slot="reference">否</el-button>
                    <el-popover
                        placement="top"
                        width="200"
                        trigger="hover"
                        :content="scope.row.ResultReference ? scope.row.ResultReference : '赞无数据'"
                        v-if="scope.row.IllegalCheck == 1"
                    >
                        <el-button type="danger" plain size="mini" slot="reference">是</el-button>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="ReviewTime" label="审核时间" show-overflow-tooltip align="center" min-width="100"></el-table-column>
            <el-table-column prop="ReviewStatus" label="审核状态" show-overflow-tooltip align="center" min-width="100">
                <template scope="scope">
                    <el-tag v-if="scope.row.ReviewStatus == 2" type="danger">审核驳回</el-tag>
                    <el-tag v-else-if="scope.row.ReviewStatus == 1" type="success">审核通过</el-tag>
                    <el-tag v-else>待审核</el-tag>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-col :span="24" class="pageBarContainer">
            <div>已选择<span class="selectNumCla">{{allSelect.length}}</span>条</div>
            <div>
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pages.PageIndex"
                    :page-sizes="[10, 20, 40, 100]"
                    :page-size="pages.PageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pages.DataCount"
                    class='pageBar'>
                </el-pagination>
            </div>
        </el-col>
        <!-- 查看照片 -->
        <el-dialog
            :title="dialogTitle"
            :visible.sync="viewPictureDialog"
            width="50%"
            :close-on-click-modal='true'
        >
            <div class="viewExamDetails">
                <div>考试开始时间：{{needTableData && needTableData.StartTime ? needTableData.StartTime : ""}}</div>
                <div>交卷时间：{{needTableData && needTableData.HandTime ? needTableData.HandTime : ""}}</div>
                <div>考试时长：{{needTableData && needTableData.ExamTime ? needTableData.ExamTime : ""}}</div>
            </div>
            <el-table
                v-loading='viewPictureLoading'
                :data="viewPictureTable"
                border
                highlight-current-row
            >   
                <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                <el-table-column prop="CreateTime" label="抓拍时间" align="center"></el-table-column>
                <el-table-column prop="DataUrl" label="照片" align="center">
                    <template slot-scope='scope'>
                        <el-popover placement='right' trigger='hover'>
                            <img :src="scope.row.DataUrl" alt="" style='width:120px;height:150px;text-align:center'> 
                            <img slot="reference" :src="scope.row.DataUrl" alt="" style='width: auto;height: auto;max-width: 30px;max-height: 30px;text-align:center'>                   
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="Status" label="对比结果" align="center">
                    <template slot-scope='scope'>
                        <el-tag type='success' v-if='scope.row.Status == 1'>成功</el-tag>
                        <el-tag type='danger' v-else>失败</el-tag>
                    </template>
                </el-table-column>
            </el-table>  
            <span slot="footer" class="dialog-footer">
                <el-button @click="viewPictureDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
        <!-- 人声监测 -->
        <el-dialog
            :title="soundDialogTitle"
            :visible.sync="viewSoundDialog"
            width="70%"
            :close-on-click-modal='true'
        >
            <el-table
                v-loading='viewSoundLoading'
                :data="viewSoundTable"
                border
                highlight-current-row
            >   
                <el-table-column type='index' label='序号' width='60' align="center"></el-table-column>
                <el-table-column prop="DataUrl" label="音频" align="center">
                    <template slot-scope='scope'>
                        <a :href="scope.row.DataUrl" target="_blank">{{scope.row.DataUrl}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="DataUrl" label="音频翻译" align="center">
                    <template slot-scope='scope'>
                        {{JSON.parse(scope.row.ResultJson)[0].result.text}}
                    </template>
                </el-table-column>
                <el-table-column prop="CreateTime" label="监测时间" align="center" width="180"></el-table-column>
            </el-table>  
            <span slot="footer" class="dialog-footer">
                <el-button @click="viewSoundDialog = false">关 闭</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Toolbar from "../../components/Toolbar"
import { getButtonList } from "../../promissionRouter";
import { getCertificationReviewListPage,examinationReview,examinationReviewBatch,getTakePhotosList,getDataDictionaryList,getRecordingMonitoringList } from '@/api/api'
export default {
    components:{Toolbar},
    data(){
        return {
            // 查询条件按钮
            filters:{
                name: '',
                postName: '',
                industryName: '',
                systemExaminationPaper: '',
                examAbnormal: '',
                checkStatus: 0,
	            endMark:undefined,
	            startMark:undefined,
            },
            buttonList: [],
            postNameArr: [],
            industryNameArr: [],
            allSelect: [],
            systemExaminationPaperArr: [{
                name: '是',
                value: 1
            },{
                name: '否',
                value: 0
            }],
            checkStatusArr: [{
                name: '待审核',
                value: 0
            },{
                name: '审核通过',
                value: 1
            },{
                name: '审核驳回',
                value: 2
            }],
            // 列表
            needTableData: '',
            tableLoading: false,
            tableData:[],
            pages:{
                PageIndex:1,
                PageSize:20,
                DataCount:0
            },
            // 查看照片
            dialogTitle: '抓拍照片记录',
            viewPictureDialog: false,
            viewPictureLoading: false,
            viewPictureTable: [],
            // 人声监测
            soundDialogTitle: '人声监测记录',
            viewSoundDialog: false,
            viewSoundLoading: false,
            viewSoundTable: [],
        } 
    },
    methods:{
        callFunction(item) {
            this[item.Func].apply(this, item);
        },
        // 查询
        getCertificationReviewListPage() {
            this.getTableData()
        },
        // 审核通过
        passAudit() {
            var selectionData = this.$refs.multipleTable.selection
            // if(this.needTableData) {
            if(selectionData.length > 0) {
                this.$confirm('确定审核通过？',"提示",{
                }).then(() => {
                    // 单个审核参数
                    // var params = {
                    //     status: 1,
                    //     studentExamineePaperID: this.needTableData.ID
                    // }
                    // 批量审核参数
                    var params = {
                        Status: 1,
                    }
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    params.ExamPaperIdList = ids
                    this.auditCertificateFun(params)
                })
                .catch(() => {

                })
            } else {
                this.$message.warning("请至少选择一条数据")
            }
        },
        // 审核驳回
        rejectAudit() {
            var selectionData = this.$refs.multipleTable.selection
            // if(this.needTableData) {
            if(selectionData.length > 0) {
                this.$confirm('确定审核驳回？',"提示",{
                }).then(() => {
                    // 单个审核参数
                    // var params = {
                    //     status: 2,
                    //     studentExamineePaperID: this.needTableData.ID
                    // }
                    // 批量审核参数
                    var params = {
                        Status: 2,
                    }
                    var ids = []
                    selectionData.forEach(item => {
                        ids.push(item.ID)
                    });
                    params.ExamPaperIdList = ids
                    this.auditCertificateFun(params)
                })
                .catch(() => {
                    
                })
            } else {
                this.$message.warning("请至少选择一条数据")
            }
        },
        auditCertificateFun(params) {
            // 单个审核通过/驳回
            // examinationReview(params).then(res => {
            // 批量审核通过/驳回
            examinationReviewBatch(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.$message.success(result.Message)
                    this.getTableData()
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 获取页面数据
        getTableData(){
					if((this.filters.startMark&&this.filters.endMark)||(!this.filters.startMark&&!this.filters.endMark)){
						this.tableLoading = true
						var params = {
							pageIndex: this.pages.PageIndex,
							pageSize: this.pages.PageSize,
							stationId: this.filters.postName,
							tradeId: this.filters.industryName,
							forceCommit: this.filters.systemExaminationPaper,
							illegalCheck: this.filters.examAbnormal,
							reviewStatus: this.filters.checkStatus,
							startMark: this.filters.startMark,
							endMark: this.filters.endMark
						}
						if(this.filters.name) {
							params.name = this.filters.name
						}
						getCertificationReviewListPage(params).then(res => {
							var result = res.data
							if(result.Success) {
								this.tableLoading = false
								this.tableData = result.Response.Data
								this.pages.DataCount = result.Response.DataCount
							} else {
								this.tableLoading = false
								this.$message.error(result.Message)
							}
							
						})
					}else {
						this.$message({
							message: '起始分数、最高分数需要同时填写',
							type: 'warning'
						})
					}
        
        },
        currentChange(row){
            this.needTableData = row     
        },
        // 查看照片
        viewBatPicture(val) {
            this.dialogTitle = "抓拍照片记录"
            this.viewPictureDialog = true
            var params = {
                studentExamineePaperId: val.ID
            }
            this.getPictureListFun(params);
        },
        // 查看异常照片
        viewabnormalPicture(val) {
            this.dialogTitle = "人脸对比失败照片记录"
            this.viewPictureDialog = true
            var params = {
                status: 0,
                studentExamineePaperId: val.ID
            }
            this.getPictureListFun(params);
        },
        // 获取照片列表
        getPictureListFun(params) {
            this.viewPictureLoading = true
            getTakePhotosList(params).then(res => {
                var result = res.data
                this.viewPictureLoading = false
                if(result.Success) {
                    this.viewPictureTable = result.Response
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 人声监测
        viewBatSound(val) {
            this.soundDialogTitle = "人声监测记录"
            this.viewSoundDialog = true
            var params = {
                studentExamineePaperId: val.ID,
                status: 0,
            }
            this.getSoundListFun(params);
        },
        // 获取人声监测列表
        getSoundListFun(params) {
            this.viewSoundLoading = true
            getRecordingMonitoringList(params).then(res => {
                var result = res.data
                this.viewSoundLoading = false
                if(result.Success) {
                    this.viewSoundTable = result.Response
                } else {
                    this.$message.error(result.Message)
                }
            })
        },
        // 分页
        handleSizeChange(value){
            this.pages.PageSize = value
            this.getTableData()
        },
        handleCurrentChange(value){
            this.pages.PageIndex = value
            this.getTableData()
        },
        // 选中表格行
        handleSelectChange(allRow){
            this.allSelect = allRow
        },
        // 获取岗位/行业信息
        getPostMsgFun() {
            var params = {
                dataType: 1
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.postNameArr = result.Response
                }
            })
        },
        getIndustryMsgFun() {
            var params = {
                dataType: 0
            }
            getDataDictionaryList(params).then(res => {
                var result = res.data
                if(result.Success) {
                    this.industryNameArr = result.Response
                }
            })
        },
    },
    created(){
        let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : [];
        this.buttonList = getButtonList(this.$route.path, routers);
    },
    mounted() {
        this.getTableData()
        this.getPostMsgFun()
        this.getIndustryMsgFun()
    }
}
</script>

<style lang="stylus" scoped>
    .pageBarContainer {
        display: flex;
        align-items: center;
        font-size: 14px;
				height :70px;
    }
    .selectNumCla {
        color: #409EFF;
        font-weight: bold;
        margin: 0 5px;
        font-size: 16px;
    }
    .viewExamDetails {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
    }
</style>